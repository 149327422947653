import { Component, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import {  MatDialog,
  MatDialogRef } from '@angular/material';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { DialogNotificationComponent } from '../../dialogs/dialog-notification/dialog-notification.component';
import { LessonService } from '../lessons.service';

@Component({
  selector: 'app-lesson-create',
  templateUrl: './lessons-create.component.html',
  styleUrls: [
    './lessons-create.component.css',
    '../../app.component.css'
  ]
})

export class LessonCreateComponent implements OnDestroy {
  apiResponse: Subscription;
  serviceResponse: any;

  constructor(
    private dialog: MatDialog,
    private lessonService: LessonService,
    private location: Location
  ) {}

  saveLesson(form: NgForm) {
    this.lessonService.createLesson(
      form.value.title,
      form.value.type,
      form.value.description,
      form.value.url_video,
      form.value.task,
      form.value.file,
      form.value.end_date,
    );

    this.apiResponse = this.lessonService.getApiListener()
      .subscribe((response) => {
        this.dialog.open(DialogNotificationComponent, {
          width: '450px',
          data: {objectID: 'lesson', title: 'Success', message: response}
        });
      });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  ngOnDestroy() {
    if (this.apiResponse !== undefined) {
      this.apiResponse.unsubscribe();
    }
  }
}
