import {  Component,
  OnInit,
  OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {  MatDialog,
MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../profiles/profiles.service';
import { LandingProfiles } from '../../profiles/profiles.model';
import { DialogNotificationComponent } from '../../dialogs/dialog-notification/dialog-notification.component';

@Component({
selector: 'app-student-profile-edit',
templateUrl: './profile-edit.component.html',
styleUrls: [
'./profile-edit.component.css',
'../../app.component.css'
]
})

export class StudentProfileEditComponent implements OnInit, OnDestroy {
apiResponse: Subscription;
serviceResponse: any;
applicantEmail = '';
profileData: LandingProfiles;

constructor(
public profileService: ProfileService,
public route: ActivatedRoute,
private dialog: MatDialog
) {}

ngOnInit() {
this.route.paramMap.subscribe((paramMap: ParamMap) => {
if (paramMap.has('email')) {
this.applicantEmail = paramMap.get('email');
console.log('email from url', this.applicantEmail);

this.profileService.getOneProfile(this.applicantEmail);
this.profileService.getApiListener().subscribe(profile => {
  this.profileData = {
    age: profile[0].age,
    dui: profile[0].dui,
    email: profile[0].email,
    english_level: profile[0].english_lvl,
    first_name: profile[0].first_name,
    last_name: profile[0].last_name,
    phone: profile[0].phone,
    portfolio_url: profile[0].portfolio_url,
    speciality: profile[0].speciality,
    university: profile[0].university,
    app_status: profile[0].status,
  };
  console.log('response de api', this.profileData);
});
}
});
}

sendEmail(email: string) {
this.profileService.sendInterviewEmail(email);
this.apiResponse = this.profileService.getApiListener()
.subscribe((response) => {
this.dialog.open(DialogNotificationComponent, {
  width: '450px',
  data: {objectID: 'emailSent', title: 'Success', message: response}
});
});
}
ngOnDestroy() {

}
}
