import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Lesson } from './lessons.model';
import { environment } from '../../environments/environment';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

const apiUrl = environment.apigatewayUrl + 'dev/lessons';

@Injectable({providedIn: 'root'})

export class LessonService {
  private apiResponse = new Subject;
  private token: any;

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  createLesson(
    name: string,
    type: string,
    description: string,
    video_url: string,
    task: string,
    file: boolean,
    end_date: string
  ) {
    const d = new Date();
    const datestring = d.getDate()  + '' + (d.getMonth() + 1) + d.getFullYear() + '-' + d.getHours() + d.getMinutes() + d.getSeconds();
    const lesson: Lesson = {
      id: 'lesson_' + datestring,
      name: name,
      type: type,
      description: description,
      video_url: video_url,
      task: task,
      file: file ? file : false,
      end_date: end_date,
    };
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
      }
      this.http.post(apiUrl, lesson, {
        headers: new HttpHeaders({'Authorization': session.getIdToken().getJwtToken()})
      }).subscribe(response => {
        this.apiResponse.next([response]);
      }, error => {
        console.log('ERROR FROM API', error);
      });
    });
  }

  getAllLessons() {
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
        return;
      }
      this.http.get(apiUrl + '/all', {
        headers: new HttpHeaders({'Authorization': session.getIdToken().getJwtToken()})
      }).subscribe((response) => {
        console.log('response from api', response[0]);
        this.apiResponse.next([response]);
      }, error => {
        console.log('ERROR ON REQUEST', error);
      });
    });
  }

  getLessonsByType(type: string): Observable<any> {
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
        return;
      }
      this.token = session.getIdToken().getJwtToken();
    });
    return this.http.get(apiUrl + '/' + type, {
      headers: new HttpHeaders({'Authorization': this.token})
    });
  }

  getLessonById(id: string) {
    return this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
        return;
      }
      return this.http.get(apiUrl + '/' + id, {
        headers: new HttpHeaders({'Authorization': session.getIdToken().getJwtToken()})
      });
    });
  }

  clearListener() {
      this.apiResponse.next([]);
  }

  getApiListener() {
    return  this.apiResponse.asObservable();
  }
}
