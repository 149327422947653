import { Component } from '@angular/core';
import { Form } from '@angular/forms';

@Component({
  selector: 'app-landing-contact',
  templateUrl: './contact.component.html',
  styleUrls: [
    './contact.component.css',
    '../app.component.css'
  ]
})

export class LandingComponent {
  register(form: Form) {

  }
}
