import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import {  MatDialog } from '@angular/material';
import { DialogNotificationComponent } from '../../dialogs/dialog-notification/dialog-notification.component';

@Component({
  selector: 'app-verify',
  templateUrl: './verification.component.html',
  styleUrls: [
    './verification.component.css',
    '../../app.component.css'
  ]
})

export class VerifyUserComponent implements OnInit {
  didFail = false;
  isLoading = false;

  constructor(private authService: AuthService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.authService.authIsLoading.subscribe(
      (isLoading: boolean) => this.isLoading = isLoading
    );
    this.authService.authDidFail.subscribe(
      (didFail: boolean) => this.didFail = didFail
    );
  }

  onConfirm(formValue: { username: string, validationCode: string }) {
    this.authService.confirmUser(formValue.username, formValue.validationCode);

    this.authService.getErrorListener()
      .subscribe((response) => {
        this.dialog.open(DialogNotificationComponent, {
          width: '450px',
          data: {objectID: 'id', title: 'Error', message: response}
        });
      });
  }
}
