import {  Component,
  Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
  } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-notification',
  templateUrl: './dialog-notification.component.html',
  styleUrls: [
    './dialog-notification.component.css',
    '../../app.component.css'
  ]
})

export class DialogNotificationComponent {
  objectID: string;
  title: string;
  message: string;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.objectID = data.objectID;
      this.title = data.title;
      this.message = data.message;
     }

  close() {
    if (this.objectID === 'emailSent') {
      this.dialogRef.close();
      this.router.navigate(['/profiles']);
    } else if (this.objectID === 'lesson') {
      this.dialogRef.close();
      this.router.navigate(['/lessons']);
    } else if (this.objectID === 'student') {
      this.dialogRef.close();
      this.router.navigate(['/profiles']);
    } else {
      this.dialogRef.close();
    }
  }
}
