import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { CognitoUserPool,
        CognitoUserAttribute,
        CognitoUser,
        AuthenticationDetails,
        CognitoUserSession } from 'amazon-cognito-identity-js';

import { environment } from '../../environments/environment';

import { User } from './user.model';
import { Student } from '../students/students.model';

const POOL_DATA = {
  UserPoolId : 'us-east-1_cBPpyEzpH',
  ClientId : '5iu00qfmivjij41nh3rem01vmc'
};

const userPool = new CognitoUserPool(POOL_DATA);
const studentApi = environment.apigatewayUrl + 'dev/students';

@Injectable()
export class AuthService {
  authIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<boolean>(false);
  authStatusChanged = new Subject<boolean>();
  registeredUser: CognitoUser;
  error = new Subject;
  response = new Subject;
  userAttr = new Subject;
  user_role = '';

  constructor(private router: Router, private http: HttpClient) {}

  signUp(username: string, email: string, password: string, role: string): void {
    this.authIsLoading.next(true);

    const user: User = {
      username: username,
      email: email,
      password: password,
      role: role
    };

    const attrList: CognitoUserAttribute[] = [];

    const emailAttribute = {
      Name: 'email',
      Value: user.email
    };

    const roleAttribute = {
      Name: 'custom:role',
      Value: user.role
    };

    attrList.push(new CognitoUserAttribute(emailAttribute));
    attrList.push(new CognitoUserAttribute(roleAttribute));

    userPool.signUp(user.username, user.password, attrList, null, (err, result) => {
      if (err) {
        console.log('SIGNUP ERR', err);
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        this.error.next(err.message);
        return;
      }
      this.authDidFail.next(false);
      this.authIsLoading.next(false);
      this.registeredUser = result.user;
      this.response.next('User created successfully');
    });
    return;
  }

  signUpStudent(
    username: string,
    email: string,
    password: string,
    role: string,
    applicant_email: string,
    m1: any,
    m2: any,
    m3: any,
    drive: string
  ): void {
    this.authIsLoading.next(true);

    const user: User = {
      username: username,
      email: email,
      password: password,
      role: role
    };

    const attrList: CognitoUserAttribute[] = [];

    const emailAttribute = {
      Name: 'email',
      Value: user.email
    };

    const roleAttribute = {
      Name: 'custom:role',
      Value: user.role
    };

    attrList.push(new CognitoUserAttribute(emailAttribute));
    attrList.push(new CognitoUserAttribute(roleAttribute));

    userPool.signUp(user.username, user.password, attrList, null, (err, result) => {
      if (err) {
        console.log('SIGNUP ERR', err);
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        this.error.next(err.message);
        return;
      }
      const student: Student = {
        username: username,
        email: email,
        applicant_email: applicant_email,
        introVideo: false,
        progress: 0,
        drive_url: drive,
        m1: m1,
        m2: m2,
        m3: m3
      };
      this.http.post(studentApi, student)
      .subscribe(response => {
        this.response.next([response]);
      }, error => {
        console.log('ERROR FROM API', error);
        this.error.next(error);
      });
      this.authDidFail.next(false);
      this.authIsLoading.next(false);
      this.registeredUser = result.user;
    });

    return;
  }

  confirmUser(username: string, code: string) {
    this.authIsLoading.next(true);
    console.log(username);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        this.error.next(err.message);
        return;
      }
      this.authDidFail.next(false);
      this.authIsLoading.next(false);
      this.router.navigate(['/login']);
    });
  }

  signIn(username: string, password: string) {
    this.authIsLoading.next(true);
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.authenticateUser(authDetails, {
      onSuccess (result: CognitoUserSession) {
        that.authStatusChanged.next(true);
        that.authDidFail.next(false);
        that.authIsLoading.next(false);
        that.getUserAttributes();
        that.getUserAttributesListener().subscribe(attributes => {
          if (attributes[2].Name === 'custom:role') {
            that.user_role = attributes[2].Value;
            if (that.user_role === 'admin') {
              that.router.navigate(['/profiles']);
            } else if (that.user_role === 'student') {
              that.router.navigate(['/student/dashboard']);
            }
          } else {
            that.user_role = '';
          }
        });
      },
      onFailure (err) {
        that.authDidFail.next(true);
        that.authIsLoading.next(false);
        console.log('MESSAGE FROM API', err.message);
        that.error.next(err.message);
      }
    });
    return;
  }

  getAuthenticatedUser() {
    return userPool.getCurrentUser();
  }

  getUserAttributes() {
    const user = userPool.getCurrentUser();

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          console.log('Session error', err);
          return;
        }
        user.getUserAttributes((error, attributes) => {
          if (error) {
            console.log('Error getting attributes', error);
          }
          this.userAttr.next([...attributes]);
        });
      });
    }
  }

  logout() {
    this.getAuthenticatedUser().signOut();
    this.authStatusChanged.next(false);
    this.router.navigate(['/login']);
  }

  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = Observable.create((observer) => {
      if (!user) {
        observer.next(false);
      } else {
        user.getSession((err, session) => {
          if (err) {
            observer.next(false);
          } else {
            if (session.isValid()) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          }
        });

      }
      observer.complete();
    });
    return obs;
  }

  initAuth() {
    this.isAuthenticated().subscribe(
      (auth) => this.authStatusChanged.next(auth)
    );
  }

  getErrorListener() {
    return  this.error.asObservable();
  }

  getMessageListener() {
    return  this.response.asObservable();
  }

  getUserAttributesListener() {
    return this.userAttr.asObservable();
  }
}
