import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth-guard.service';
import { LandingComponent } from './landing-contact/contact.component';
import { LessonCreateComponent } from './lessons/lessons-create/lessons-create.component';
import { LessonsListComponent } from './lessons/lessons-list/lessons-list.component';
import { LoginComponent } from './auth/login/login.component';
import { ProfilesListComponent } from './profiles/profile-list/profile-list.component';
import { ProfileShowComponent } from './profiles/profile-show/profile-show.component';
import { SignupComponent } from './auth/signup/signup.component';
import { StudentCreateComponent } from './students/students-create/students-create.component';
import { StudentDashboardComponent } from './students/students-dashboard/students-dashboard.component';
import { StudentProfileEditComponent } from './students/students-profile-edit/profile-edit.component';
import { VerifyUserComponent } from './auth/verification/verification.component';

const routes: Routes = [
  { path: '',                         component: LandingComponent },
  { path: 'lessons',                  component: LessonsListComponent, canActivate: [AuthGuard] },
  { path: 'lesson/create',            component: LessonCreateComponent, canActivate: [AuthGuard] },
  { path: 'login',                    component: LoginComponent },
  { path: 'profiles',                 component: ProfilesListComponent, canActivate: [AuthGuard] },
  { path: 'profile/:email',           component: ProfileShowComponent, canActivate: [AuthGuard] },
  { path: 'signup-admin',             component: SignupComponent, canActivate: [AuthGuard] },
  { path: 'student/create/:email',    component: StudentCreateComponent, canActivate: [AuthGuard] },
  { path: 'student/dashboard',        component: StudentDashboardComponent, canActivate: [AuthGuard] },
  { path: 'student/profile/edit/:email', component: StudentProfileEditComponent, canActivate: [AuthGuard] },
  { path: 'verify-user',              component: VerifyUserComponent }
];

@NgModule ({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [AuthGuard]
})

export class AppRoutingModule {

}
