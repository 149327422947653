import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import {  MatDialog,
  MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { ProfileService } from '../profiles.service';
import { DialogNotificationComponent } from '../../dialogs/dialog-notification/dialog-notification.component';

@Component({
  selector: 'app-landing-form',
  templateUrl: './landing-form.component.html',
  styleUrls: [
    './landing-form.component.css',
    '../../app.component.css'
  ]
})
export class LandingFormComponent {
  apiResponse: Subscription;
  university = '';
  serviceResponse: any;

  constructor(public profileService: ProfileService, private dialog: MatDialog) {}

  register(form: NgForm) {
    console.log('FORMULARIO', form.value);

    if (form.invalid) {
      return;
    } else {
      if (form.value.other_university) {
        this.profileService.createProfile(
          form.value.age,
          form.value.dui,
          form.value.email,
          form.value.english_lvl,
          form.value.first_name,
          form.value.last_name,
          form.value.phone,
          form.value.url_portfolio,
          form.value.speciality,
          form.value.other_university
        );
      } else {
        this.profileService.createProfile(
          form.value.age,
          form.value.dui,
          form.value.email,
          form.value.english_lvl,
          form.value.first_name,
          form.value.last_name,
          form.value.phone,
          form.value.url_portfolio,
          form.value.speciality,
          form.value.university
        );
      }

      this.apiResponse = this.profileService.getApiListener()
          .subscribe((response) => {
            this.serviceResponse = response;
            if (response[0].errorMessage) {
              if (response[0].errorMessage === 'Unable to create profile.') {
                this.dialog.open(DialogNotificationComponent, {
                  width: '450px',
                  data: {objectID: 'id', title: 'ERROR!', message: 'SE HA PRODUCIDO UN ERROR GUARDANDO EL PERFIL, INTÉNTALO DE NUEVO.'}
                });
              } else if (response[0].errorMessage === 'Email already in use') {
                this.dialog.open(DialogNotificationComponent, {
                  width: '450px',
                  data: {objectID: 'id', title: 'ERROR!', message: 'ESTE CORREO ELECTRÓNICO YA SE ENCUENTRA REGISTRADO.'}
                });
              } else {
                this.dialog.open(DialogNotificationComponent, {
                  width: '450px',
                  data: {objectID: 'id', title: 'ERROR!', message: 'SE HA PRODUCIDO UN ERROR GUARDANDO EL PERFIL, INTÉNTALO DE NUEVO.'}
                });
              }
            } else {
              this.dialog.open(DialogNotificationComponent, {
                width: '450px',
                data: {objectID: 'id', title: '¡GRACIAS!', message: 'TUS DATOS HAN SIDO REGISTRADOS CORRECTAMENTE.'}
              });

              form.resetForm();
            }
          });
    }

  }

  onKeyOnlyNumbers(evt: any) {
    if (evt.which !== 8 && evt.which !== 0 && evt.which !== 46 && evt.which < 48 || evt.which > 57) {
       evt.preventDefault();
   }
  }
}
