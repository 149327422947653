import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {  faFacebookF,
          faVimeoV,
          faYoutube,
          faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import {  faBars } from '@fortawesome/free-solid-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthService } from './auth/auth.service';
import { DialogNotificationComponent } from './dialogs/dialog-notification/dialog-notification.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LandingComponent } from './landing-contact/contact.component';
import { LandingFormComponent } from './profiles/landing-create/landing-form.component';
import { LessonCreateComponent } from './lessons/lessons-create/lessons-create.component';
import { LessonsListComponent } from './lessons/lessons-list/lessons-list.component';
import { LoginComponent } from './auth/login/login.component';
import { MenuComponent } from './menu/menu.component';
import { ProfilesListComponent } from './profiles/profile-list/profile-list.component';
import { ProfileShowComponent } from './profiles/profile-show/profile-show.component';
import { SignupComponent } from './auth/signup/signup.component';
import { StudentCreateComponent } from './students/students-create/students-create.component';
import { StudentDashboardComponent } from './students/students-dashboard/students-dashboard.component';
import { StudentProfileEditComponent } from './students/students-profile-edit/profile-edit.component';
import { VerifyUserComponent } from './auth/verification/verification.component';

@NgModule({
  declarations: [
    AppComponent,
    DialogNotificationComponent,
    FooterComponent,
    HeaderComponent,
    LandingComponent,
    LandingFormComponent,
    LessonCreateComponent,
    LessonsListComponent,
    LoginComponent,
    MenuComponent,
    ProfilesListComponent,
    ProfileShowComponent,
    SignupComponent,
    StudentCreateComponent,
    StudentDashboardComponent,
    StudentProfileEditComponent,
    VerifyUserComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    FlexLayoutModule,
    FontAwesomeModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
  entryComponents: [ DialogNotificationComponent ]
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(faFacebookF, faVimeoV, faYoutube, faLinkedinIn, faBars);
  }
}
