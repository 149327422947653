import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LandingProfiles } from './profiles.model';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

const apiUrl = environment.apigatewayUrl + 'dev/profiles';
const emailUrl = environment.apigatewayUrl + 'dev/email-interview';

@Injectable({providedIn: 'root'})

export class ProfileService {

  private apiResponse = new Subject;
  private profiles: LandingProfiles[];
  private profilesFromApi: LandingProfiles[] = [];

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  createProfile(
    age: number,
    dui: string,
    email: string,
    english_level: string,
    first_name: string,
    last_name: string,
    phone: string,
    portfolio_url: string,
    speciality: string,
    university: string,
  ) {
    const profile: LandingProfiles = {
      age: age,
      dui: dui,
      email: email,
      english_level: english_level,
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      portfolio_url: portfolio_url,
      speciality: speciality,
      university: university,
      app_status: 'none'
    };

    this.http.post(apiUrl, profile)
      .subscribe(response => {
        this.apiResponse.next([response]);
      }, error => {
        console.log('ERROR FROM API', error);
      });
  }

  getProfiles() {
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
        return;
      }
      this.http.get<{profiles}>(apiUrl + '/all', {
        headers: new HttpHeaders({'Authorization': session.getIdToken().getJwtToken()})
      }).subscribe((response) => {
        this.apiResponse.next([response]);
      }, error => {
        console.log('ERROR ON REQUEST', error);
      });
    });
  }

  getOneProfile(email: string) {
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
        return;
      }
      return this.http.get(apiUrl + '/' + email, {
        headers: new HttpHeaders({'Authorization': session.getIdToken().getJwtToken()})
      }).subscribe((response) => {
        this.apiResponse.next([response[0]]);
      }, error => {
        console.log('ERROR ON REQUEST', error);
      });
    });
  }

  sendInterviewEmail(email: string) {
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
        return;
      }
      const reqData = {
        email: email
      };
      this.http.post(emailUrl, reqData, {
        headers: new HttpHeaders({'Authorization': session.getIdToken().getJwtToken()})
      })
      .subscribe(response => {
        console.log('response from api', response);
        this.apiResponse.next([response]);
      }, error => {
        console.log('ERROR FROM API', error);
      });
    });
  }

  getApiListener() {
    return  this.apiResponse.asObservable();
  }
}
