import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ProfileService } from '../profiles.service';
import { Subscription } from 'rxjs';
import { LandingProfiles } from '../profiles.model';

@Component({
  selector: 'app-profiles-list',
  templateUrl: './profile-list.component.html',
  styleUrls: [
    './profile-list.component.css',
    '../../app.component.css'
  ]
})

export class ProfilesListComponent implements OnInit {
  displayedColumns: string[] = [
    'first_name',
    'last_name',
    'email',
    'phone',
    'university',
    'english_lvl',
    'portfolio_url',
    'status',
    'action'
  ];

  @ViewChild(MatSort) sort: MatSort;

  profiles: LandingProfiles[] = [];
  tableData = [];
  dataSource: any;
  private profilesSub: Subscription;
  isLoading = false;

  constructor(private profileService: ProfileService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.profileService.getProfiles();
    this.profilesSub = this.profileService.getApiListener().subscribe((profiles) => {
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(profiles[0]);
      this.dataSource.sort = this.sort;
    });

  }

  elementClicked(profile_id) {
    console.log('click on:', profile_id);
  }

  applyFilter(filterValue: string) {
    /*
    this.profiles.filter(function (value) {
      value.university = filterValue.trim().toLowerCase();
    });
    */
  }
}
