import {  Component,
  OnInit,
  OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  MatDialog,
MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { StudentService } from '../students.service';
import { Student } from '../students.model';
import { DialogNotificationComponent } from '../../dialogs/dialog-notification/dialog-notification.component';
import { LessonService } from '../../lessons/lessons.service';
import { AuthService } from '../../auth/auth.service';

@Component({
selector: 'app-students-dashboard',
templateUrl: './students-dashboard.component.html',
styleUrls: [
'./students-dashboard.component.css',
'../../app.component.css'
]
})

export class StudentDashboardComponent implements OnInit, OnDestroy {
  apiResponse: Subscription;
  serviceResponse: any;
  cognitoUser: any;
  username = '';
  studentData: any;
  m1StudentLessons = [];
  m2StudentLessons = [];
  m3StudentLessons = [];
  isLoading = false;

  constructor(
    public authService: AuthService,
    public studService: StudentService,
    public lessonService: LessonService,
    public route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.cognitoUser = this.authService.getAuthenticatedUser();

    this.studService.getOneProfile(this.cognitoUser.username);
    this.studService.getApiListener().subscribe(student => {
      if (student[0]) {
        this.studentData = student[0];

        student[0].m1.forEach(lesson => {
          const lessonId = lesson.LessonId;
          this.lessonService.getLessonById(lessonId).subscribe(lessonData => {
            const lessons = {
              'id' : lesson.LessonId,
              'completed': lesson.Completed,
              'data': lessonData[0]
            };
            this.m1StudentLessons.push(lessons);
            this.m1StudentLessons.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
          });
        });
        student[0].m2.forEach(lesson => {
          const lessonId = lesson.LessonId;
          this.lessonService.getLessonById(lessonId).subscribe(lessonData => {
            const lessons = {
              'id' : lesson.LessonId,
              'completed': lesson.Completed,
              'data': lessonData[0]
            };
            this.m2StudentLessons.push(lessons);
            this.m2StudentLessons.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
          });
        });
        student[0].m3.forEach(lesson => {
          const lessonId = lesson.LessonId;
          this.lessonService.getLessonById(lessonId).subscribe(lessonData => {
            const lessons = {
              'id' : lesson.LessonId,
              'completed': lesson.Completed,
              'data': lessonData[0]
            };
            this.m3StudentLessons.push(lessons);
            this.m3StudentLessons.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
          });
        });
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.router.navigate(['/']);
      }

      console.log('m1', this.m1StudentLessons);
      console.log('m2', this.m2StudentLessons);
      console.log('m3', this.m3StudentLessons);
      console.log('student data', student[0]);

    });
    console.log(this.cognitoUser.username);
  }

  takeLesson(lesson: any) {
    console.log('lesson data', lesson);
  }

  ngOnDestroy() {

  }
}
