import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {
  }
  isAuthenticated = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {

    this.authService.isAuthenticated().subscribe(auth => {
      this.isAuthenticated = auth;
    });

    if (!this.isAuthenticated) {
      this.router.navigate(['/login']);
    }
    return this.isAuthenticated;
  }
}
