import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.css',
    '../../app.component.css'
  ]
})

export class LoginComponent implements OnInit {
  private error: string;
  private redirect_url = '';
  serviceError: any;
  errorSub: Subscription;

  didFail = false;
  isLoading = false;

  constructor(private authService: AuthService, public route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.authService.authIsLoading.subscribe(
      (isLoading: boolean) => this.isLoading = isLoading
    );
    this.authService.authDidFail.subscribe(
      (didFail: boolean) => this.didFail = didFail
    );
    this.authService.authStatusChanged.subscribe(
      (authenticated) => {
        console.log('is auth', authenticated);
        if (authenticated) {
          this.router.navigate(['/profiles']);
        }
      });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      /*
      if (paramMap.has('error')) {
        this.error = paramMap.get('error');
      }
      if (paramMap.has('attempt_url')) {
        // this.redirect_url = paramMap.get('attempt_url');
        // console.log('hay un redirect!', this.redirect_url);
      }
      */
    });
  }

  login(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const usrName = form.value.username;
    const password = form.value.password;
    this.authService.signIn(usrName, password);

    // this.authService.login(form.value.email, form.value.password, this.redirect_url);

    this.errorSub = this.authService.getErrorListener()
          .subscribe((error) => {
            this.serviceError = error;
          });
  }
}
