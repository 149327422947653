import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { LessonService } from '../lessons.service';
import { Subscription } from 'rxjs';
import { Lesson } from '../lessons.model';

@Component({
  selector: 'app-lessons-list',
  templateUrl: './lessons-list.component.html',
  styleUrls: [
    './lessons-list.component.css',
    '../../app.component.css'
  ]
})

export class LessonsListComponent implements OnInit {
  isLoading = false;
  displayedColumns: string[] = [
    'name',
    'type',
    'description',
    'video_url',
    'task',
    'file',
    'end_date',
    'lesson_id'
  ];

  @ViewChild(MatSort) sort: MatSort;

  tableData = [];
  dataSource: any;

  constructor(private lessonService: LessonService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.lessonService.getAllLessons();
    this.lessonService.getApiListener().subscribe((lessons) => {
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(lessons[0]);
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter(filterValue: string) {
    /*
    this.profiles.filter(function (value) {
      value.university = filterValue.trim().toLowerCase();
    });
    */
  }
}
