import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Student } from './students.model';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

import { AuthService } from '../auth/auth.service';

const apiUrl = environment.apigatewayUrl + 'dev/students/dashboard';

@Injectable({providedIn: 'root'})

export class StudentService {
  private apiResponse = new Subject;

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  getOneProfile(username: string) {
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      if (err) {
        console.log('ERROR GETTING SESSION', err);
        return;
      }
      return this.http.get(apiUrl + '?username=' + username).subscribe((response) => {
        this.apiResponse.next([response[0]]);
      }, error => {
        console.log('ERROR ON REQUEST', error);
      });
    });
  }
  getApiListener() {
    return  this.apiResponse.asObservable();
  }
}
