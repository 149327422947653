import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { NgForm } from '@angular/forms';
import {  MatDialog } from '@angular/material';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DialogNotificationComponent } from '../../dialogs/dialog-notification/dialog-notification.component';
import { LessonService } from '../../lessons/lessons.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-students-create',
  templateUrl: './students-create.component.html',
  styleUrls: [
    './students-create.component.css',
    '../../app.component.css'
  ]
})

export class StudentCreateComponent implements OnInit {
  didFail = false;
  isLoading = false;
  lessons3: any;
  lessons2: any;
  lessons1: any;
  applicantEmail = '';

  @ViewChild('usrForm') form: NgForm;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private lessonService: LessonService,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('email')) {
        this.applicantEmail = paramMap.get('email');
      }
    });

    this.lessonService.getLessonsByType('M1').subscribe(lessons => {
      if (lessons.errorMessage) {
        return;
      } else {
        this.lessons1 = [...lessons];
      }
    });

    this.lessonService.getLessonsByType('M2').subscribe(lessons => {
      if (lessons.errorMessage) {
        return;
      } else {
        this.lessons2 = [...lessons];
      }
    });

    this.lessonService.getLessonsByType('M3').subscribe(lessons => {
      if (lessons.errorMessage) {
        return;
      } else {
        this.lessons3 = [...lessons];
      }
    });
  }

  onSubmit() {
    const usrName = this.form.value.username;
    const email = this.form.value.email;
    const password = this.form.value.password;
    const m1 = [];
    const m2 = [];
    const m3 = [];

    this.form.value.m1.forEach(element => {
      m1.push({LessonId: element, Completed: false});
    });
    this.form.value.m2.forEach(element => {
      m2.push({LessonId: element, Completed: false});
    });
    this.form.value.m3.forEach(element => {
      m3.push({LessonId: element, Completed: false});
    });

    const role = 'student';
    const drive_url = this.form.value.drive;

    this.authService.signUpStudent(usrName, email, password, role, this.applicantEmail, m1, m2, m3, drive_url);
    this.authService.getMessageListener()
      .subscribe((response) => {
        this.dialog.open(DialogNotificationComponent, {
          width: '450px',
          data: {objectID: 'student', title: 'Success', message: response}
        });
      });

      this.authService.getErrorListener()
      .subscribe((response) => {
        this.dialog.open(DialogNotificationComponent, {
          width: '450px',
          data: {objectID: 'id', title: 'Error', message: response}
        });
      });


    // this.form.resetForm();

  }

}
