import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';
import {  MatDialog } from '@angular/material';
import { DialogNotificationComponent } from '../../dialogs/dialog-notification/dialog-notification.component';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: [
    './signup.component.css',
    '../../app.component.css'
  ]
})

export class SignupComponent implements OnInit {
  confirmUser = false;
  didFail = false;
  isLoading = false;
  @ViewChild('usrForm') form: NgForm;

  constructor(private authService: AuthService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.authService.authIsLoading.subscribe(
      (isLoading: boolean) => this.isLoading = isLoading
    );
    this.authService.authDidFail.subscribe(
      (didFail: boolean) => this.didFail = didFail
    );
  }

  onSubmit() {
    const usrName = this.form.value.username;
    const email = this.form.value.email;
    const password = this.form.value.password;
    const role = 'admin';

    this.authService.signUp(usrName, email, password, role);
    this.authService.getMessageListener()
      .subscribe((response) => {
        this.dialog.open(DialogNotificationComponent, {
          width: '450px',
          data: {objectID: 'id', title: 'Success', message: response}
        });
      });

      this.authService.getErrorListener()
      .subscribe((response) => {
        this.dialog.open(DialogNotificationComponent, {
          width: '450px',
          data: {objectID: 'id', title: 'Error', message: response}
        });
      });

    this.form.resetForm();
  }

}
