import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: [
    './menu.component.css',
    '../app.component.css'
  ]
})

export class MenuComponent implements OnInit {
  isAuthenticated = false;
  user_role = '';

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    this.user_role = '';

    this.authService.authStatusChanged.subscribe(
      (authenticated) => {
        if (authenticated) {
          this.authService.getUserAttributes();
          this.authService.getUserAttributesListener().subscribe(attributes => {
            if (attributes[2].Name === 'custom:role') {
              this.isAuthenticated = authenticated;
              this.user_role = attributes[2].Value;
            } else {
              this.user_role = '';
            }
            console.log('USR ROLE', this.user_role);
          });
        }
      });
    this.authService.initAuth();
  }

  onLogout() {
    this.authService.logout();
  }
}
